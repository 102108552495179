stickyHeader();

/**
 * Prevent Default Function
 */
function preventDef(e) {
	e.preventDefault();
	e.stopPropagation();
}

/**
 * Sticky Header
 */
function stickyHeader() {
	const header = document.querySelector("#masthead");
	const scrollUp = "sticky";
	const scrollDown = "sticky-up";
	let lastScroll = 0;

	window.addEventListener("scroll", () => {
		const currentScroll = window.pageYOffset;
		if (currentScroll <= 200) {
			header.classList.remove(scrollUp);
			header.classList.remove(scrollDown);
			return;
		}

		if (currentScroll > lastScroll && !header.classList.contains(scrollDown)) {
			// down
			header.classList.remove(scrollUp);
			header.classList.add(scrollDown);
		} else if (currentScroll < lastScroll && header.classList.contains(scrollDown)) {
			// up
			header.classList.remove(scrollDown);
			header.classList.add(scrollUp);
		}
		lastScroll = currentScroll;
	});
}



/**
 * Dropdown Search Form
 */

const toggleSearchForm = (btn, form) => {
	btn.addEventListener('click', () => {
		form.classList.toggle('collapsed')
	})
}

const dropdownSearchform = (el) => {

	let form = document.getElementById(el),
		btn = document.querySelectorAll('.btn-show-form');

	btn.forEach(item => {
		toggleSearchForm(item, form)
	})


}
dropdownSearchform('searchform')

/**
 * Modal Window
 */
class ModalWindow {
	constructor(id) {
		this.id = id
		this.modal = document.getElementById(id)
		if (this.modal) {
			this.triggerOpen = document.querySelector(`[data-modal=${id}]`)
			this.triggerClose = this.modal.querySelectorAll('.close')
			this.init()
		}
	}

	// Init Method
	init() {
		this.open = this.open.bind(this)
		this.close = this.close.bind(this)
		if (this.triggerOpen) {
			this.triggerOpen.addEventListener('click', this.open);
		}
		if (this.triggerClose) {
			this.triggerClose.forEach(item => {
				item.addEventListener('click', this.close)
			})
		}
	}
	// Open Modal
	open(e) {
		e.preventDefault()
		this.modal.classList.remove('hidden')
	}
	// Close Modal
	close(e) {
		e.preventDefault()
		this.modal.classList.add('hidden')
	}
}
const inquire = new ModalWindow('inquire-form')
inquire


const scrollToTopButton = () => {
	let scrollTopBtn = document.querySelector('.scroll-to-top');
	if(!scrollTopBtn) false;

	scrollTopBtn.addEventListener('click', (e) => {
		e.preventDefault()
		window.scrollTo({
			top: 0,
			behavior: "smooth"
	});
	})
};
scrollToTopButton();

/**
 * Mobile Menu
 */
const mobileMenu = () => {
	let el = document.querySelectorAll('.menu-toggle');
	for (var i = 0; i < el.length; i++) {
		el[i].addEventListener('click', function () {
			document.querySelector('.header-navs-inner').classList.toggle('active');
			document.querySelector('.menu-open').classList.toggle('active');
			document.querySelector('.btn-show-form-mobile').classList.toggle('show');
			document.body.classList.toggle('open')
			// document.querySelector('.menu-bg').classList.toggle('active');
		});
	}
}

/**
 * Sub Menu on Mobile
 */
function mobSubMenu() {
	let item = document.querySelectorAll('.primary-menu .menu-item-has-children');
	item.forEach(el => {
		let link = el.querySelector('a:first-child'),
			// back = el.querySelector('.back'),
      close = document.querySelector('.menu-open');
		if (window.innerWidth < 992) {
			link.addEventListener('click', preventDef, false);
			link.addEventListener('click', (e) => {
				el.classList.toggle('show-sub-menu');
				//prevMenu(back, el);
				// document.querySelector('.menu-container').scrollTo({
				// 	top: 0,
				// 	behavior: 'smooth'
				// });
			});
		} else {
			link.removeEventListener('click', preventDef, false);
			if (close.classList.contains('open')) {
				menuToggle(close);
			}
		}
	});
}

mobileMenu();
mobSubMenu();